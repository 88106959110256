
























































































































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniInputError from '@/components/inputs/SygniInputError.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniDatePicker from '@/components/inputs/SygniDatePicker.vue';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniToggleSwitch from '@/components/inputs/SygniToggleSwitch.vue';
import Attachments from '../../../modules/templates/components/Attachments.vue';
import AttachmentModal from '../../templates/components/AttachmentModal.vue';
import SubscriptionSeriesTable from '../components/SubscriptionSeriesTable.vue';
import { required, minLength, minValue } from 'vuelidate/lib/validators';
import { Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Dictionaries } from '@/modules/accounting/store/types';
import { usedCurrencies } from '@/shared/consts';
import { Subscription, ShareListOption, SeriesOfSharesObject } from '../store/types';
import { Template } from '../../templates/store/types';
import { SelectOption } from '@/store/types';
import _ from 'lodash';
import { create, all } from 'mathjs'
import moment from 'moment';
const math = create(all);

Component.registerHooks(['validations'])
@Component({
  components: { SygniContainerTitle, SubscriptionSeriesTable, SygniRectButton, SygniInput, SygniToggleSwitch, SygniSelect, SygniDatePicker, SygniLinkButton, SygniLoader, Attachments, AttachmentModal, SygniInputError },
  ...mapGetters('accounting', {
    dictionaries: 'getDictionaries',
  })
})
export default class SubscriptionModule extends Vue {
  readonly supportedFileFormats: Array<string> = ['pdf'];
  isLoading: boolean = false;
  dictionaries!: Dictionaries;
  usedCurrencies: any = usedCurrencies;
  templatesList: Template[] = [];
  seriesOfSharesList: ShareListOption[] = []

  showAttachmentPreviewModal: boolean = false;
  selectedAttachment: any = null;
  includeTemplates: boolean = false;
  registrationData: { date: string, status: string } = {
    date: null,
    status: 'Unregistered'
  }
  attachments: any[] = []
  useCdn: boolean = true
  
  @Prop({ default: 'add ' }) viewType: 'add' | 'edit'

  subscription: Subscription = {
    status: null,
    code: null,
    agreementDate: null,
    currency: 'PLN',
    productAgreementTemplateId: null,
    listOfPurchasersOfSharesTemplateId: null,
    resolutionId: null,
    seriesOfShares: null,
    llcShares: null,
  }

  get isSubscriptionDisabled() {
    return this.isMarketplaceAccountant || this.isSubscriptionAuthorised
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  get shareListOptionCounters() {
    const counters: any = {}

    this.seriesOfSharesList?.forEach((el: any) => {
      let shareValue: number = el?.nextCounterFrom

      const newShareListOptions: SeriesOfSharesObject[] = this.subscription?.seriesOfShares?.filter((item: SeriesOfSharesObject) => !item?.original && item?.name === el?.seriesOfSharesName && item?.instrumentCount)

      newShareListOptions?.forEach((item: SeriesOfSharesObject) => {
        shareValue = math.number(math.add(math.bignumber(shareValue), math.bignumber(item?.instrumentCount || 0)))
      })

      this.$set(counters, el?.seriesOfSharesName, shareValue)
    })

    return counters
  }

  get shareListOptions() {
    return this.seriesOfSharesList?.map((el: ShareListOption) => {
      return { label: el?.seriesOfSharesName, value: el?.seriesOfSharesName }
    })
  }

  get templateOptions(): SelectOption[] {
    return this.templatesList.map((el: Template) => {
      return { label: el.title, value: el.id }
    })
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData']
  }

  get isLlc() {
    return this.activeUserData?.equityType !== 'JOINT_STOCK_SHARES'
  }

  get isEditMode() {
    return this.viewType === 'edit'
  }

  get canRegister() {
    return this.isSubscriptionAuthorised && this.subscription?.agreementDate
  }

  get isSubscriptionAuthorised() {
    return this.subscription?.status?.toLowerCase() === 'passed'
  }

  get totalInvestmentValue() {
    if (this.isLlc) {
      const nominalValue = this.subscription?.llcShares?.issueValue || 0
      const instrumentCount = this.subscription?.llcShares?.instrumentCount || 0
      return math.number(math.bignumber(math.multiply(instrumentCount, nominalValue)))
    } else {
      return (this.subscription.seriesOfShares as Array<any>).reduce((accumulator: any, object: any) => {
        return accumulator + math.number(math.multiply(math.bignumber(object.instrumentCount), math.bignumber(object.issueValue)))
      }, 0);
    }
  }

  get validationObject() {
    const validation: any = {
      subscription: {
        code: { required },
        agreementDate: { required },
        currency: { required },
        productAgreementTemplateId: {},
        listOfPurchasersOfSharesTemplateId: {},
        resolutionId: {},
      }
    }

    if (this.isLlc) {
      validation.subscription.llcShares = {
        instrumentCount: { required, minValue: minValue(1) },
        nominalValue: { required },
        issueValue: { required },
      }
    } else {
      validation.subscription.seriesOfShares = {
        required,
        minLength: minLength(1),
        $each: {
          name: { required },
          instrumentCount: { required, minValue: minValue(1) },
          counterFrom: { required, minValue: minValue(1) },
          counterTo: { required },
          nominalValue: { required },
          issueValue: { required },
        }
      }
    }

    return validation
  }

  registerDateDisabled(date: Date) {
    if (moment(date).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
      return true
    }

    if (this.subscription?.agreementDate) {
      if (moment(date).format('YYYY-MM-DD') >= moment(this.subscription?.agreementDate).format('YYYY-MM-DD')) {
        return false
      }
    }

    return true;
  }

  setRegistrationDate(date: string) {
    this.registrationData.date = date  
  }

  async handleSubscriptionRegister() {
    try {
      if (this.registrationData?.date) {
        await this.$store.dispatch('subscriptions/registerSubscription', { id: this.$route.params.id, data: { registrationDate: this.registrationData?.date } })
      } else {
        await this.$store.dispatch('subscriptions/unregisterSubscription', this.$route.params.id)
      }
      
      this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise?page=subscriptions` })
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Subscription updated'
      })

    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
  }

  setSeriesName(value: string, item: SeriesOfSharesObject) {
    this.$set(item, 'name', value)
    this.$set(item, 'counterFrom', this.shareListOptionCounters[value] || 1)
    this.$set(item, 'counterTo', null)
    this.$set(item, 'instrumentCount', null)
    this.$set(item, 'nominalValue', null)
    this.$set(item, 'issueValue', null)
  }

  calculateInstrumentCount(value: string, item: SeriesOfSharesObject) {
    this.$set(item, 'counterTo', value)

    if (Number(value) > Number(item?.counterFrom)) {
      const instrumentCount = math.number(math.subtract(math.bignumber(item?.counterTo + 1 || 0), math.bignumber(item?.counterFrom || 0)))
      this.$set(item, 'instrumentCount', instrumentCount)
    }
  }

  calculateCounterTo(value: string, item: SeriesOfSharesObject) {
    this.$set(item, 'instrumentCount', value)

    if (Number(value) > 0) {
      const counterTo = math.number(math.add(math.bignumber(item?.counterFrom || 0), math.bignumber(item?.instrumentCount - 1 || 0)))
      this.$set(item, 'counterTo', counterTo)
    }
  }

  handleAddNewSeriesOption(option: SelectOption, item: SeriesOfSharesObject) {
    const isFilled: boolean = !!(option?.label?.replaceAll(' ', ''))
    if (isFilled) {
      const hasOptionAlready = this.shareListOptions?.find((el: any) => el?.label === option?.label)
  
      if (!hasOptionAlready) {
        this.seriesOfSharesList.push({ nextCounterFrom: 1, seriesOfSharesName: option?.label })
      }
  
      this.setSeriesName(option?.label, item)
    }
  }

  gotToSubscriptionsPage() {
    this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise?page=subscriptions` })
  }

  openAttachmentPreview(attachment: any, useCdn: boolean) {
    this.useCdn = useCdn;
    this.selectedAttachment = attachment;
    this.showAttachmentPreviewModal = true;
  }

  closeAttachmentPreviewModal() {
    this.showAttachmentPreviewModal = false;
  }

  handleAgreement(files: any[]) {
    if (files?.length) {
      this.subscription.resolutionId = files[0].referenceId
    } else {
      this.subscription.resolutionId = null
    }
  }

  handleAttachments(files: any[]) {
    this.attachments = files
  }

  removeSeriesOfSharesItem(index: number) {
    if (this.subscription.seriesOfShares?.length > 1) {
      this.subscription.seriesOfShares.splice(index, 1);
    }
  }

  addSeriesOfSharesItem() {
    this.subscription.seriesOfShares.push({
      name: null,
      instrumentCount: null,
      counterFrom: null,
      counterTo: null,
      nominalValue: null,
      issueValue: null,
    });
  }

  prepareSubscriptionData() {
    if (this.isLlc) {
      this.subscription.llcShares = {
        instrumentCount: null,
        nominalValue: null,
        issueValue: null
      }
    } else {
      this.subscription.seriesOfShares = [
        {
          name: null,
          instrumentCount: null,
          counterFrom: null,
          counterTo: null,
          nominalValue: null,
          issueValue: null,
        }
      ]
    }
  }

  async loadSubscriptionData() {
    const subscription = await this.$store.dispatch('subscriptions/getSubscription', this.$route.params?.id)

    this.$nextTick(() => {
      this.subscription.code = subscription?.code || null,
      this.subscription.agreementDate = subscription?.agreementDate || null,
      this.subscription.currency = subscription?.currency || null,
      this.subscription.listOfPurchasersOfSharesTemplateId = subscription?.listOfPurchasersOfSharesTemplate?.id || null
      this.subscription.productAgreementTemplateId = subscription?.productAgreementTemplate?.id || null
      this.subscription.resolutionId = subscription?.resolutionFileId || null;
      this.subscription.status = subscription?.status || null;

      this.registrationData.date = subscription?.registrationDate || null;
      this.registrationData.status = this.$options.filters.capitalizeFirstLetter(subscription?.registrationStatus) || 'Unregistered';
      
      if (subscription?.seriesOfShares) {
        this.subscription.seriesOfShares = subscription?.seriesOfShares?.map((el: any) => {
          el.original = true
          
          return el
        })
      }
      
      if (subscription?.llcShares?.instrumentCount) {
        this.subscription.llcShares = subscription?.llcShares
      }
      
      if (this.subscription?.listOfPurchasersOfSharesTemplateId || this.subscription?.productAgreementTemplateId) {
        this.includeTemplates = true
      }

      if (subscription?.resolutionFile) {
        (this.$refs.attachments as Attachments).attachmentsToUpload = [{
          name: subscription?.resolutionFile?.fileName,
          type: 'FILE',
          path: subscription?.resolutionFile?.filePath,
          referenceId: subscription?.resolutionFile?.id,
        }]
      }

      if (this.isSubscriptionAuthorised) {
        const attachments: any[] = subscription?.attachments?.map((el: any) => {
          return {
            name: el?.name,
            type: 'FILE',
            path: el?.filePath,
            referenceId: el?.fileId
          }
        });
        (this.$refs.documents as Attachments).attachmentsToUpload = attachments
        this.attachments = attachments
      }
    })
  }

  buildSubscriptionData() {
    const payload: Subscription = _.cloneDeep(this.subscription)

    if (!this.includeTemplates) {
      payload.listOfPurchasersOfSharesTemplateId = null
      payload.productAgreementTemplateId = null
    }

    return payload
  }

  async doAction() {
    this.$v?.$touch()

    if (!this.$v?.$error) {
      this.isLoading = true

      if (this.isSubscriptionAuthorised) {
        const attachmentsPayload: any = {
          attachments: this.attachments?.map((el: any) => {
            return { fileId: el.referenceId, name: el.name }
          })
        }
        await this.$store.dispatch('subscriptions/updateSubscriptionAttachments', { id: this.$route.params?.id, data: attachmentsPayload })
        await this.handleSubscriptionRegister()
      } else {
        const subscriptionData: any = this.buildSubscriptionData()
  
        try {
          const action = this.viewType === 'add' ? 'subscriptions/postSubscription' : 'subscriptions/editSubscription'
          const payload = this.viewType === 'add' ? subscriptionData : { subscriptionId: this.$route.params?.id, data: subscriptionData }
          await this.$store.dispatch(action, payload)
          this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise?page=subscriptions` })
          this.$notify({
            duration: 2500,
            type: 'success',
            title: 'Success',
            text: `Subscription ${this.viewType === 'add' ? 'created' : 'updated'}!`
          })
        } catch (e) {
          const errorMessage = this.$options.filters.errorHandler(e)
          this.$notify({
            duration: 2500,
            type: 'error',
            title: 'Error',
            text: errorMessage
          })
        }
      }

      this.isLoading = false
    }
  }

  async created() {
    this.prepareSubscriptionData()
    this.templatesList = await this.$store.dispatch('templates/getTemplateChoices', 'template');

    this.seriesOfSharesList = await this.$store.dispatch('subscriptions/getSeriesOfShares')

    if (this.viewType === 'edit') {
      await this.loadSubscriptionData()
    }
  }

  validations() {
    return this.validationObject
  }
}
